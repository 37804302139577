import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";

const PaginaDeInicio = () => {
  const [wordIndex, setWordIndex] = useState(0);

  const words = [
    "Transformamos tu visión en resultados tangibles",
    "Revolucionamos tu camino hacia el éxito",
    "Creamos oportunidades que lideran el futuro",
  ];
  const colors = ["#6C4DE2", "#FBBC05", "#0B8DB5"]; // Morado, Amarillo y Azul

  useEffect(() => {
    const interval = setInterval(() => {
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 4000);

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minHeight: { xs: "110vh", md: "88vh" },
        color: "#211E26",
        textAlign: "center", // Centra el texto
      }}
    >
      {/* Título */}
      <Typography
        variant="h2"
        sx={{
          width: { xs: "80%", md: "60%" },
          fontWeight: "bold",
          marginBottom: "20px",
          fontSize: { xs: "1.9rem", md: "2.3rem" },
          animation: `slideUp 1s ease-out`,
        }}
      >
        Somos el motor digital de la transformación económica en Latinoamérica,
        <br />
        <span
          style={{
            color: colors[wordIndex], // Color dinámico
            fontWeight: "bold",
            animation: `slideUp 1s ease-out`, // Animación de aparición
          }}
        >
          {words[wordIndex]}
        </span>
      </Typography>

      {/* Subtítulo */}
      <Typography
        variant="h5"
        sx={{
          width: { xs: "85%", md: "45%" },
          fontWeight: "400",
          marginBottom: "30px",
          fontSize: { xs: "1rem", md: "16px" },
        }}
      >
        Ayudamos a marcas, empresas y profesionales con soluciones innovadoras
        de capitalización digital, creando oportunidades de crecimiento
        sostenibles y generando un impacto económico positivo.
      </Typography>

      {/* Botones */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "15px",
          marginBottom: "30px",
          alignItems: "center", // Asegura que los botones se centren horizontalmente
          justifyContent: "center", // Asegura que los botones se centren en el contenedor
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: { xs: "100%", sm: "250px" }, // 100% en pantallas pequeñas, 250px en pantallas más grandes
            backgroundColor: "#F21C63",
            color: "#fff",
            padding: "10px 20px",
            fontWeight: "500",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#f22066",
            },
          }}
        >
          Soy una Marca/Empresa
        </Button>
        <Button
          variant="outlined"
          sx={{
            width: { xs: "100%", sm: "250px" }, // 100% en pantallas pequeñas, 250px en pantallas más grandes
            borderColor: "#F21C63",
            color: "#F21C63",
            padding: "10px 20px",
            fontWeight: "500",
            textTransform: "none",
            "&:hover": {
              borderColor: "#bf0a46",
              color: "#bf0a46",
            },
          }}
        >
          Soy un Profesional
        </Button>
      </Box>

      <Typography
        variant="body1"
        sx={{
          color: "#0B8DB5",
          fontFamily: "Poppins", // Fuente personalizada
          width: "80%", // Limita el ancho para mejor lectura
          fontSize: "0.8rem",
          fontWeight: "600",
          lineHeight: "1.6",
          textTransform: "uppercase",
          animation: "bounce 2s infinite", // Aplica la animación
          "@keyframes bounce": {
            "0%, 100%": {
              transform: "translateY(0)", // Posición inicial y final
            },
            "50%": {
              transform: "translateY(-1px)", // Desplazamiento hacia arriba
            },
          },
        }}
      >
        En hitpoly Capitalizas de forma segura.
      </Typography>
    </Box>
  );
};

export default PaginaDeInicio;
