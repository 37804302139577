import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MarketingIcon from "@mui/icons-material/AdUnits"; // Icono de marketing
import StoreIcon from "@mui/icons-material/Store"; // Icono de comercio
import DevicesIcon from "@mui/icons-material/Devices"; // Icono de tecnología

const UneteALaComunidad = () => {
  const [openSubMenu, setOpenSubMenu] = useState(false); // Estado para controlar el submenú
  const [clickEffect, setClickEffect] = useState(false); // Estado para el efecto de clic

  const subMenuRef = useRef(null); // Referencia al submenú para detectar clics fuera de él

  const handleSubMenuToggle = () => {
    setOpenSubMenu((prevState) => !prevState); // Alternar la visibilidad del submenú
  };

  // Manejar clics fuera del componente para cerrar el submenú
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
        setOpenSubMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemClick = () => {
    // Efecto de clic visual
    setClickEffect(true);
    setTimeout(() => {
      setClickEffect(false);
    }, 200); // Efecto de clic por 200ms
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "auto",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "transparent",
        borderRadius: "5px",
        border: "1px solid #F21C63",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <Typography
        sx={{
          margin: "0px 20px",
          color: "#F21C63",
          fontSize: "15px",
          marginRight: "8px",
          fontWeight: "500",
        }}
      >
        Únete a la comunidad
      </Typography>
      <Box
        sx={{
          backgroundColor: "transparent",
          borderRadius: {xs: "0px", md: "5px"},
          borderLeft: "1px solid #F21C63",
          width: "32px",
          height: {xs: "60px", md: "100%"},
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "10px",
        }}
        onClick={handleSubMenuToggle}
      >
        <ExpandMoreIcon sx={{ fontSize: "12px", color: "#F21C63" }} />
      </Box>

      {/* Submenú */}
      {openSubMenu && (
        <Box
          ref={subMenuRef}
          sx={{
            position: "absolute",
            top: "110%", 
            left: {xs: "25px", md: "40%"}, 
            marginTop: "8px", 
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000, 
            width: "auto", 
            maxWidth: "200px", 
            transition: "opacity 0.1s ease", 
            opacity: clickEffect ? 0.8 : 1, 
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              fontSize: "14px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f1f1f1", 
              },
            }}
            onClick={handleItemClick} 
          >
            <MarketingIcon sx={{ marginRight: "8px", color: "#0B8DB5" }} />
            Marketing
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              fontSize: "14px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f4f4f4", 
              },
            }}
            onClick={handleItemClick}
          >
            <StoreIcon sx={{ marginRight: "8px", color: "#0B8DB5" }} />
            Comercio
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              fontSize: "14px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f1f1f1", 
              },
            }}
            onClick={handleItemClick} 
          >
            <DevicesIcon sx={{ marginRight: "8px", color: "#0B8DB5" }} />
            Tecnología
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UneteALaComunidad;
