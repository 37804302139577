import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";

// Constantes para los datos
const SECTORES_Y_DEPARTAMENTOS = {
  sectores: [
    "Tecnología y Software",
    "Salud y Bienestar",
    "E-commerce y Retail",
    "Educación y Formación",
    "Inmobiliario",
    "Turismo y Viajes",
    "Restauración y Alimentos",
    "Consultoría y Servicios Profesionales",
  ],
  departamentos: [
    "Marketing",
    "Ventas",
    "Recursos Humanos",
    "Tecnología",
  ],
};

const NavegacionInternaSectores = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "auto",
        backgroundColor: "#fff",
      }}
    >
      {/* Hijo de 70% */}
      <Box
        sx={{
          display: "flex",
          marginLeft: "10%",
          width: "65%",
          marginRight: "1%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
            position: "relative",
            marginTop: "40px",
            marginBottom: "60px",
          }}
        >
          {/* Título */}
          <Typography
            variant="h4"
            sx={{
              fontWeight: "400",
              fontSize: "1.1rem",
            }}
          >
            Sectores y Departamentos
          </Typography>

          {/* Línea horizontal */}
          <Box
            sx={{
              width: "100%",
              height: "0.5px",
              backgroundColor: "#ddd",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          ></Box>

          {/* Grid con columnas */}
          <Grid container spacing={2} sx={{width: "60%"}}>
            {/* Columna 1 - Sectores */}
            <Grid item xs={6}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                SECTORES
              </Typography>
              {/* Items */}
              {SECTORES_Y_DEPARTAMENTOS.sectores.map((sector, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.85rem",
                      width: "90%",
                      "&:hover": {
                        color: "#F21C63",
                      },
                    }}
                  >
                    {sector}
                  </Typography>
                </Box>
              ))}
            </Grid>

            {/* Columna 2 - Departamentos */}
            <Grid item xs={6}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                DEPARTAMENTOS EMPRESARIALES
              </Typography>
              {/* Items */}
              {SECTORES_Y_DEPARTAMENTOS.departamentos.map((departamento, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.85rem",
                      width: "90%",
                      "&:hover": {
                        color: "#F21C63",
                      },
                    }}
                  >
                    {departamento}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Hijo de 30% */}
      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          width: "35%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
          paddingLeft: "5%",
        }}
      >
        {/* Enlaces */}
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none", 
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63", 
            },
          }}
        >
          Noticias Importantes
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            marginBottom: "10px",
            fontWeight: "500",
            "&:hover": {
              color: "#F21C63", 
            },
          }}
        >
          Recursos de Crecimiento
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Asociaciones tecnológicas
        </Link>
      </Box>
    </Box>
  );
};

export default NavegacionInternaSectores;
