import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { LaptopMac, LocalHospital, ShoppingCart, School, Home, FlightTakeoff, Restaurant, Business, Campaign, Sell, Group, Devices } from "@mui/icons-material";

// Constantes para los datos
const SECTORES_Y_DEPARTAMENTOS = {
  sectores: [
    { nombre: "Tecnología y Software", icono: <LaptopMac sx={{ color: "#FF5722" }} /> },
    { nombre: "Salud y Bienestar", icono: <LocalHospital sx={{ color: "#FFEB3B" }} /> },
    { nombre: "E-commerce y Retail", icono: <ShoppingCart sx={{ color: "#4CAF50" }} /> },
    { nombre: "Educación y Formación", icono: <School sx={{ color: "#2196F3" }} /> },
    { nombre: "Inmobiliario", icono: <Home sx={{ color: "#9C27B0" }} /> },
    { nombre: "Turismo y Viajes", icono: <FlightTakeoff sx={{ color: "#FF9800" }} /> },
    { nombre: "Restauración y Alimentos", icono: <Restaurant sx={{ color: "#FF5722" }} /> },
  ],
  departamentos: [
    { nombre: "Marketing", icono: <Campaign sx={{ color: "#FF5722" }} /> },
    { nombre: "Ventas", icono: <Sell sx={{ color: "#FFEB3B" }} /> },
    { nombre: "Recursos Humanos", icono: <Group sx={{ color: "#4CAF50" }} /> },
    { nombre: "Tecnología", icono: <Devices sx={{ color: "#2196F3" }} /> },
  ],
};


const NavegacionInternaSectores = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      {/* Hijo de 70% */}
      <Box sx={{ display: "flex", height: "80%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            position: "relative",
            marginTop: "40px",
          }}
        >
          {/* Título */}
          <Typography
            variant="h4"
            sx={{ fontWeight: "400", fontSize: "1.2rem" }}
          >
            Sectores y Departamentos
          </Typography>

          {/* Línea horizontal */}
          <Box
            sx={{
              width: "100%",
              height: "1px",
              backgroundColor: "#ddd",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          />

          {/* Grid con columnas */}
          <Grid container spacing={2} sx={{ width: "100%",}}>
            {/* Columna 1 - Sectores */}
            <Grid item xs={6} sx={{ width: "50%" }}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.8rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                SECTORES
              </Typography>
              {/* Items */}
              {SECTORES_Y_DEPARTAMENTOS.sectores.map((sector, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#F21C63",
                    },
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{sector.icono}</Box>
                  {/* Nombre */}
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.7rem",
                    }}
                  >
                    {sector.nombre}
                  </Typography>
                </Box>
              ))}
            </Grid>

            {/* Columna 2 - Departamentos */}
            <Grid item xs={6}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.8rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                DEPARTAMENTOS
              </Typography>
              {/* Items */}
              {SECTORES_Y_DEPARTAMENTOS.departamentos.map(
                (departamento, index) => (
                  <Box
                    key={index}
                    sx={{
                      mt: 3,
                      mb: 1,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#F21C63",
                      },
                    }}
                  >
                    {/* Icono */}
                    <Box sx={{ mr: 2 }}>{departamento.icono}</Box>
                    {/* Nombre */}
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "400",
                        fontSize: "0.7rem",
                      }}
                    >
                      {departamento.nombre}
                    </Typography>
                  </Box>
                )
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ position: "relative", mt: 4, heigth: "20%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f4f4f4",
            padding: 2,
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              fontSize: "0.9rem",
              mb: 1,
            }}
          >
            Recursos:
          </Typography>
          <Link
            href="#"
            sx={{
              display: "block",
              color: "#211E26",
              textDecoration: "none",
              fontSize: "0.7rem",
              fontWeight: "500",
              mb: 1,
              "&:hover": {
                color: "#F21C63",
              },
            }}
          >
            Noticias
          </Link>
          <Link
            href="#"
            sx={{
              display: "block",
              color: "#211E26",
              textDecoration: "none",
              fontSize: "0.7rem",
              fontWeight: "500",
              mb: 1,
              "&:hover": {
                color: "#F21C63",
              },
            }}
          >
            Crecimiento
          </Link>
          <Link
            href="#"
            sx={{
              display: "block",
              color: "#211E26",
              textDecoration: "none",
              fontSize: "0.7rem",
              fontWeight: "500",
              mb: 1,
              "&:hover": {
                color: "#F21C63",
              },
            }}
          >
            Asociaciones
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default NavegacionInternaSectores;
