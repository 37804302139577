import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import {
  Group ,
  AddShoppingCart,
  Email,
  InsertChart,
  Code,
  Business,
  Settings,
} from "@mui/icons-material"; // Importar los iconos

// Constantes para los datos
const SERVICIOS = {
  pequeñasEmpresas: [
    {
      titulo: "Redes Sociales",
      subtitulo: "Alcanza grandes audiencias mediante contenido atractivo",
      icono: <Group  sx={{ color: "#1877F2" }} />,
    },
    {
      titulo: "Publicidad Digital",
      subtitulo:
        "Consigue tráfico cualificado, leads, clientes potenciales, ventas, etc",
      icono: <AddShoppingCart sx={{ color: "#F21C63" }} />,
    },
    {
      titulo: "Email Marketing",
      subtitulo:
        "Aumenta el retorno de tu inversión con estrategias de lead nurturing",
      icono: <Email sx={{ color: "#FF5722" }} />,
    },
  ],
  grandesEmpresas: [
    {
      titulo: "Inbound Marketing",
      subtitulo:
        "Mejora el ROAS con estrategias personalizadas de inbound marketing",
      icono: <InsertChart sx={{ color: "#4CAF50" }} />,
    },
    {
      titulo: "Asesoría de Inbound Marketing",
      subtitulo:
        "Asesoramos a tus equipos y los adaptamos a la metodología inbound",
      icono: <Business sx={{ color: "#9C27B0" }} />,
    },
    {
      titulo: "Automatización",
      subtitulo:
        "Mejora la eficiencia de tus procesos mediante su automatización",
      icono: <Settings sx={{ color: "#FFC107" }} />,
    },
  ],
  solucionesAvanzadas: [
    {
      titulo: "Desarrollo de Software",
      subtitulo:
        "Construimos sistemas SaaS, IaaS y PaaS fáciles de adoptar y desplegar",
      icono: <Code sx={{ color: "#2196F3" }} />,
    },
  ],
};

const NavegacionInternaServicios = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "auto",
        backgroundColor: "#fff",
      }}
    >
      {/* Hijo de 70% */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "65%",
          marginLeft: "10%",
          marginRight: "1%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
            position: "relative",
            marginTop: "40px",
            marginBottom: "60px",
          }}
        >
          {/* Título */}
          <Typography
            variant="h4"
            sx={{
              fontWeight: "400",
              fontSize: "1.1rem",
            }}
          >
            Servicios
          </Typography>

          {/* Línea horizontal */}
          <Box
            sx={{
              width: "100%",
              height: "0.5px",
              backgroundColor: "#ddd",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          ></Box>

          {/* Grid con columnas */}
          <Grid container spacing={2}>
            {/* Columna 1 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA MARCAS Y PEQUEÑAS EMPRESAS
              </Typography>
              {/* Items */}
              {SERVICIOS.pequeñasEmpresas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.85rem",
                        width: "90%",
                        "&:hover": {
                          color: "#F21C63",
                        },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 1,
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Grid>

            {/* Columna 2 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA GRANDES EMPRESAS
              </Typography>
              {/* Items */}
              {SERVICIOS.grandesEmpresas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box sx={{cursor: "pointer",}}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.85rem",
                        width: "90%",
                        "&:hover": {
                          color: "#F21C63",
                        },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Grid>

            {/* Columna 3 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                SOLUCIONES AVANZADAS
              </Typography>
              {/* Items */}
              {SERVICIOS.solucionesAvanzadas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box sx={{cursor: "pointer",}}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.85rem",
                        width: "90%",
                        "&:hover": {
                          color: "#F21C63",
                        },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Hijo de 30% */}
      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          width: "35%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
          paddingLeft: "5%",
        }}
      >
        {/* Enlaces */}
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Noticias Importantes
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            marginBottom: "10px",
            fontWeight: "500",
            "&:hover": {
              color: "#F21C63", 
            },
          }}
        >
          Recursos de Crecimiento
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none", 
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63", 
            },
          }}
        >
          Asociasiones tecnológicas
        </Link>
      </Box>
    </Box>
  );
};

export default NavegacionInternaServicios;
