import React from "react";
import Box from "@mui/material/Box";
import { Route, Routes } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import "./App.css";
import MenuDeNavegacion from "./Views/navbar/navVers03/contenedor.jsx";
import MenuMobile from "./Views/navbar/navVers03/menuDeNavegacionMobile.jsx";

// Tus otras importaciones aquí
import Login from "./Views/login/page";
import Register from "./Views/register/page";
import DashboardPage from "./Views/dashboard/page";
import DiseñoWeb from "./Views/internalPages/diseñoWeb/pages.jsx";
import Marketing from "./Views/internalPages/Marketing/pages.jsx";
import Home from "./Views/home/page.jsx";
import Portfolio from "./Views/portfolio/page.jsx";
import Blog from "./Views/blog/page.jsx";
import Nosotros from "./Views/nosotros/page.jsx";
import Contact from "./Views/contact/page.jsx";
import PrivacyPolicy from "./Views/pageLegals/PrivacyPolicy.jsx";
import Disclaimer from "./Views/pageLegals/disclaimer.jsx";
import Terms from "./Views/pageLegals/termsandconditions.jsx";
import Copyright from "./Views/pageLegals/Copyright.jsx";
import Servicios from "./Views/servicios/page.jsx";
import ServicioDesarrolloSoftware from "./Views/home/components/servicios/data/ServicioDesarrolloSoftware.jsx";
import ServicioMarketingContenidos from "./Views/home/components/servicios/data/ServicioMarketingContenidos.jsx";
import ServicioCampañasPublicitarias from "./Views/home/components/servicios/data/ServicioCampañasPublicitarias.jsx";
import ServicioDesarrolloWeb from "./Views/home/components/servicios/data/ServicioDesarrolloDePaginasWeb.jsx";
import ServicioDesarrolloDeAplicacionesMoviles from "./Views/home/components/servicios/data/ServicioDesarrolloDeAplicaciones.jsx";
import ServicioEmailMarketing from "./Views/home/components/servicios/data/ServicioEmailMarketing.jsx";

function App() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Box sx={{backgroundColor: "#fff", position: "fixed", zIndex: 999, width: "100%", height: "65px", boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.5)",}}>
        {isLargeScreen ? <MenuDeNavegacion /> : <MenuMobile />}
      </Box>
      <Box sx={{ position: "absolute", top: "65px" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/DiseñoWeb" element={<DiseñoWeb />} />
          <Route path="/Marketing" element={<Marketing />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/termsandconditions" element={<Terms />} />
          <Route path="/Copyright" element={<Copyright />} />

          <Route path="/desarrollo-de-software-a-medida" element={<ServicioDesarrolloSoftware />} />
          <Route path="/marketing-de-contenidos" element={<ServicioMarketingContenidos />} />
          <Route path="/campañas-publicitarias" element={<ServicioCampañasPublicitarias />} />
          <Route path="/paginas-web-de-alta-conversion" element={<ServicioDesarrolloWeb />} />
          <Route path="/aplicaciones-moviles-a-medida" element={<ServicioDesarrolloDeAplicacionesMoviles />} />
          <Route path="/campañas-de-email-marketing" element={<ServicioEmailMarketing />} />
        </Routes>
      </Box>
    </>
  );
}

export default App;
