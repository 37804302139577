import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import {
  Insights,
  Article,
  Campaign,
  Psychology,
  SmartToy,
  Monitor,
} from "@mui/icons-material";

// Constantes para los datos de recursos de Hitpoly
const RECURSOS = {
  pequeñasEmpresas: [
    {
      titulo: "Guías Prácticas",
      subtitulo:
        "Documentos descargables para implementar estrategias de marketing y tecnología",
      icono: <Article sx={{ color: "#1E88E5", }} />, 
    },
    {
      titulo: "Plantillas Estratégicas",
      subtitulo:
        "Archivos editables para planificar campañas publicitarias y contenido",
      icono: <Insights sx={{ color: "#43A047" }} />, 
    },
    {
      titulo: "Checklists",
      subtitulo:
        "Listas paso a paso para ejecutar tareas de marketing digital efectivas",
      icono: <Psychology sx={{ color: "#FB8C00" }} />, 
    },
  ],
  profesionales: [
    {
      titulo: "Webinars Exclusivos",
      subtitulo:
        "Eventos en línea para aprender tendencias actuales de marketing y tecnología",
      icono: <Monitor sx={{ color: "#673AB7" }} />, 
    },
    {
      titulo: "Asesoría Personalizada",
      subtitulo:
        "Sesiones privadas para resolver dudas o adaptar estrategias a tu negocio",
      icono: <Campaign sx={{ color: "#E53935" }} />, 
    },
    {
      titulo: "Cursos Interactivos",
      subtitulo:
        "Capacitaciones virtuales con materiales exclusivos y ejercicios prácticos",
      icono: <SmartToy sx={{ color: "#FFC107" }} />, 
    },
  ],
};



const NavegacionInternaRecursos = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "auto",
        backgroundColor: "#fff",
      }}
    >
      {/* Hijo de 70% */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "65%",
          marginLeft: "10%",
          marginRight: "1%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
            position: "relative",
            marginTop: "40px",
            marginBottom: "60px",
          }}
        >
          {/* Título */}
          <Typography
            variant="h4"
            sx={{
              fontWeight: "400",
              fontSize: "1.1rem",
            }}
          >
            Recursos Digitales
          </Typography>

          {/* Línea horizontal */}
          <Box
            sx={{
              width: "100%",
              height: "0.5px",
              backgroundColor: "#ddd",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          ></Box>

          {/* Grid con columnas */}
          <Grid container spacing={2}>
            {/* Columna 1 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA MARCAS Y EMPRESAS
              </Typography>
              {/* Items */}
              {RECURSOS.pequeñasEmpresas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center", 
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.85rem",
                        width: "90%",
                        "&:hover": {
                          color: "#F21C63", 
                        },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 1,
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Grid>

            {/* Columna 2 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA PROFESIONALES
              </Typography>
              {/* Items */}
              {RECURSOS.profesionales.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center", 
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box sx={{ cursor: "pointer" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.85rem",
                        width: "90%",
                        "&:hover": {
                          color: "#F21C63",
                        },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Hijo de 30% */}
      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          width: "35%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
          paddingLeft: "5%",
        }}
      >
        {/* Enlaces */}
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none", 
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63", 
            },
          }}
        >
          Noticias Importantes
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            fontSize: "15px",
            marginBottom: "10px",
            fontWeight: "500",
            "&:hover": {
              color: "#F21C63", 
            },
          }}
        >
          Recursos de Crecimiento
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Asociasiones tecnológicas
        </Link>
      </Box>
    </Box>
  );
};

export default NavegacionInternaRecursos;
