import { Box } from "@mui/material";
import Footer from "../footer/page";
import PaginaDeInicio from "../../Views/components/banners/portadaDeInicio03/portada03";

const Home = () => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box>
        <PaginaDeInicio />
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
