import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
} from "@mui/material";
// Imagenes
import Eduina from "../../../assets/eduina.png";
import Sergio from "../../../assets/sergio.png";
import Nicolas from "../../../assets/Nicolas.png";


const TeamMember = ({ name, position, image, scale }) => {
  return (
    <Card
      container
      sx={{
        maxWidth: 300,
        backgroundColor: "#28122d",
        color: "#FFFFFF",
        background: `radial-gradient(circle at bottom left, #7311BB 1%, transparent 40%),
        radial-gradient(circle at top right, #7311BB 1%, transparent 40%)`,
        borderRadius: "20px",
        border: "1px solid #828080",
        margin: "30px auto", 
      }}
    >
      <CardMedia
        component="img"
        height="300"
        image={image}
        alt={name}
        sx={{
          objectFit: "cover",
          borderRadius: "10px 10px 0 0",
          transform: `scale(${scale})`, 
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: `scale(${scale + 0.3})`, 
          },
        }}
      />
      <CardContent
        sx={{
          backgroundColor: "transparent", 
          borderRadius: "0 0 20px 20px", 
          borderTop: "1px solid rgba(255, 255, 255, 0.2)", 
          padding: "16px",
          backdropFilter: "blur(10px)", 
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: "center", color: "#B3B3B3" }}
        >
          {position}
        </Typography>
      </CardContent>
    </Card>
  );
};

const TeamSection = () => {
  const team = [
    {
      name: "EDUINA RIVAS",
      position: "DIRECTORA DE MARKETING",
      image: Eduina,
      scale: 1,
    },
    {
      name: "SERGIO CEPEDA",
      position: "DIRECTOR DE TECNOLOGÍA",
      image: Sergio,
      scale: 1.5,
    },
    {
      name: "NICOLAS AYALA",
      position: "DESARROLLADOR DE SOFTWARE",
      image: Nicolas,
      scale: 1.2,
    },
  ];

  return (
    <Container>
      <Box
        sx={{
          paddingTop: "100px",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, color: "#B51AD8" }}>
          MIEMBROS DEL EQUIPO
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: "#FFFFFF", fontWeight: "bold", mb: 4, fontSize: {
            xs: "30px",
            sm: "2.5rem",
            md: "3rem",
            lg: "3.25rem",
          }, }}
        >
          SALUDA A NUESTRO EQUIPO
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {team.map((member, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <TeamMember
                name={member.name}
                position={member.position}
                image={member.image}
                scale={member.scale}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default TeamSection;
