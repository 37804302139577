import React from 'react';
import DiseñoWeb from '../../../../internalPages/diseñoWeb/pages.jsx';


const ServicioDesarrolloDeAplicacionesMoviles = () => {
  const title = "DESARROLLO DE APLICACIONES MÓVILES";
  const subtitle = "CONSTRUYE TU FUTURO EN LA PALMA DE TU MANO";

  const introParagraph = `En HitPoly, nuestro servicio de Desarrollo de Aplicaciones Móviles te permite llevar tu negocio al siguiente nivel en el entorno digital. Creamos aplicaciones personalizadas que se adaptan a las necesidades específicas de tu empresa, proporcionando soluciones innovadoras y funcionales para conectar con tus clientes de manera directa y efectiva, desde cualquier lugar del mundo.`; 

  const sectionsData = [
    {
      title: "Diseño de UX/UI",
      description: `Nos enfocamos en crear experiencias de usuario intuitivas y atractivas, diseñando interfaces que sean visualmente impactantes y fáciles de navegar. Cada diseño está pensado para mejorar la interacción y satisfacción del usuario, asegurando que tu aplicación destaque en el competitivo mercado de las apps móviles.`,
      imageSrc: 'images/DesarrolloUxUi.jpg',
      imageAlt: 'Diseño de UX/UI',
    },
    {
      title: "Desarrollo Personalizado",
      description: `Desarrollamos aplicaciones móviles a medida, integrando características y funcionalidades avanzadas que se alinean con los objetivos de tu negocio. Ya sea que necesites una app para iOS, Android o multiplataforma, nuestro equipo técnico garantiza un desarrollo robusto y escalable.`,
      imageSrc: 'images/DesarrolloDeSoftware.jpg',
      imageAlt: 'Desarrollo Personalizado',
    },
    {
      title: "Integración de Tecnología",
      description: `Implementamos tecnologías innovadoras como inteligencia artificial, geolocalización, notificaciones push, entre otras, para garantizar que tu aplicación esté a la vanguardia. Nos aseguramos de que tu app no solo funcione sin problemas, sino que también ofrezca funcionalidades que mejoren la experiencia del usuario.`,
      imageSrc: 'images/integraciondetecnologia.jpg',
      imageAlt: 'Integración de Tecnología',
    },
    {
      title: "Testing y Optimización",
      description: `Realizamos pruebas exhaustivas para identificar y corregir cualquier problema técnico antes del lanzamiento. Además, optimizamos la aplicación para mejorar su rendimiento, velocidad y estabilidad, asegurando una experiencia fluida para todos los usuarios.`,
      imageSrc: 'images/testingyoptimizacion.jpg',
      imageAlt: 'Testing y Optimización',
    },
    {
      title: "Mantenimiento y Actualización",
      description: `Ofrecemos un servicio continuo de mantenimiento y actualización para que tu aplicación móvil se mantenga siempre operativa y actualizada. Nuestro equipo estará disponible para realizar ajustes y mejoras, adaptándose a las nuevas necesidades de tu negocio y los cambios tecnológicos.`,
      imageSrc: 'images/DesarrolloMantenimiento.jpg',
      imageAlt: 'Mantenimiento y Actualización',
    },
  ];

  const handleCtaAction = () => {
    window.location.href = "https://meetings.hubspot.com/hitpoly"; // Reemplaza con tu URL
  };

  return (
    <DiseñoWeb 
      title={title}
      subtitle={subtitle}
      sections={sectionsData}
      introParagraph={introParagraph}
      outroParagraph={(
        <>
          En HitPoly, sabemos que el mundo digital gira en torno a la movilidad. Nuestro equipo se especializa en crear aplicaciones móviles que no solo se adaptan a las tendencias actuales, sino que también están preparadas para el futuro.
          <br /><br />
          Desde el concepto inicial hasta el lanzamiento final, estamos comprometidos con el desarrollo de soluciones que generen valor tangible para tu negocio. Con nuestras aplicaciones, podrás conectar directamente con tus clientes, ofrecerles una experiencia de usuario de calidad y mantenerte siempre un paso adelante en un mercado cada vez más competitivo.
          <br /><br />
          ¿Estás listo para transformar tu visión en una aplicación exitosa? No pierdas la oportunidad de hacer crecer tu marca en el espacio móvil. Ponte en contacto con nosotros para una consulta gratuita y descubre cómo nuestras soluciones personalizadas pueden ayudarte a alcanzar tus objetivos.
          <br /><br />
          ¡El futuro de tu negocio está en la palma de tu mano! Contáctanos hoy mismo y comencemos a desarrollar la aplicación que marcará la diferencia.
        </>
      )}
      ctaText="¡Consulta gratuita para tu app!"
      ctaAction={handleCtaAction} 
    />
  );
}

export default ServicioDesarrolloDeAplicacionesMoviles;
