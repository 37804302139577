import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { TrendingUp, School, Work } from "@mui/icons-material";

// Constantes para los datos
const SERVICIOS = {
  marcasYEmpresas: [
    {
      titulo: "Crecimiento Digital",
      subtitulo:
        "Impulsa tu marca a través de estrategias digitales que maximizan tu visibilidad y retorno de inversión.",
      icono: <TrendingUp sx={{ color: "#FF5722" }} />,
    },
  ],
  profesionales: [
    {
      titulo: "Cursos en Línea",
      subtitulo:
        "Accede a una amplia oferta de cursos diseñados para mejorar tus habilidades profesionales.",
      icono: <School sx={{ color: "#FFEB3B" }} />,
    },
    {
      titulo: "Oportunidades",
      subtitulo:
        "Descubre oportunidades para crecer profesionalmente y colaborar con otros expertos del sector.",
      icono: <Work sx={{ color: "#9C27B0" }} />,
    },
  ],
};

const NavegacionInternaSoluciones = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      {/* Hijo de 70% */}
      <Box sx={{ display: "flex", height: "80%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            position: "relative",
            marginTop: "40px",
          }}
        >
          {/* Título */}
          <Typography
            variant="h4"
            sx={{ fontWeight: "400", fontSize: "1.2rem" }}
          >
            Soluciones
          </Typography>

          {/* Línea horizontal */}
          <Box
            sx={{
              width: "100%",
              height: "1px",
              backgroundColor: "#ddd",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          />

          {/* Grid con columnas */}
          <Grid container spacing={2}>
            {/* Columna 1 */}
            <Grid item xs={12} md={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.8rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA MARCAS Y EMPRESAS
              </Typography>
              {/* Items */}
              {SERVICIOS.marcasYEmpresas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    mt: 1,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        mt: 1,
                        fontSize: "0.7rem",
                        width: "90%",
                        "&:hover": { color: "#F21C63" },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 1,
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.7rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Box>
              ))}
              {/* Enlace para ver más servicios de esta sección */}
              <Link
                href="#"
                sx={{
                  color: "#4285F4",
                  textDecoration: "none",
                  fontWeight: "600",
                  fontSize: "0.7rem",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Ver todos los servicios
              </Link>
            </Grid>

            {/* Columna 2 */}
            <Grid item xs={12} md={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA PROFESIONALES
              </Typography>
              {/* Items */}
              {SERVICIOS.profesionales.map((item, index) => (
                <Box
                  key={index}
                  sx={{ mt: 1, mb: 1, display: "flex", alignItems: "center" }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box sx={{ cursor: "pointer" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "500",
                        textTransform: "uppercase",
                        mt: 1,
                        fontSize: "0.7rem",
                        width: "90%",
                        "&:hover": { color: "#F21C63" },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.7rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Box>
              ))}
              {/* Enlace para ver más servicios de esta sección */}
              <Link
                href="#"
                sx={{
                  color: "#4285F4",
                  textDecoration: "none",
                  fontWeight: "600",
                  fontSize: "0.7rem",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Ver todos los servicios
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ position: "relative", mt: 4, heigth: "20%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f4f4f4",
            padding: 2,
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              fontSize: "0.9rem",
              mb: 1,
            }}
          >
            Recursos:
          </Typography>
          <Link
            href="#"
            sx={{
              display: "block",
              color: "#211E26",
              textDecoration: "none",
              fontSize: "0.7rem",
              fontWeight: "500",
              mb: 1,
              "&:hover": {
                color: "#F21C63",
              },
            }}
          >
            Noticias
          </Link>
          <Link
            href="#"
            sx={{
              display: "block",
              color: "#211E26",
              textDecoration: "none",
              fontSize: "0.7rem",
              fontWeight: "500",
              mb: 1,
              "&:hover": {
                color: "#F21C63",
              },
            }}
          >
            Crecimiento
          </Link>
          <Link
            href="#"
            sx={{
              display: "block",
              color: "#211E26",
              textDecoration: "none",
              fontSize: "0.7rem",
              fontWeight: "500",
              mb: 1,
              "&:hover": {
                color: "#F21C63",
              },
            }}
          >
            Asociaciones
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default NavegacionInternaSoluciones;
