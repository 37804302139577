import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import {
  TrendingUp ,
  InsertDriveFile,
  School,
  Star,
  Work,
} from "@mui/icons-material";

// Constantes para los datos
const SOLUCIONES = {
  marcasYEmpresas: [
    {
      titulo: "Crecimiento Digital",
      subtitulo:
        "Impulsa tu marca a través de estrategias digitales que maximizan tu visibilidad y retorno de inversión.",
      icono: <TrendingUp  sx={{ color: "#FF5722" }} />, 
    },
    {
      titulo: "Herramientas Útiles",
      subtitulo:
        "Accede a herramientas digitales que optimizan tu productividad y mejoran la eficiencia en tu negocio.",
      icono: <InsertDriveFile sx={{ color: "#2196F3" }} />,
    },
  ],
  profesionales: [
    {
      titulo: "Cursos en Línea",
      subtitulo:
        "Accede a una amplia oferta de cursos diseñados para mejorar tus habilidades profesionales.",
      icono: <School sx={{ color: "#FFEB3B" }} />,
    },
    {
      titulo: "Recursos Destacados",
      subtitulo:
        "Obtén acceso a recursos clave que te ayudarán a avanzar en tu carrera y alcanzar tus objetivos.",
      icono: <Star sx={{ color: "#FF9800" }} />,
    },
    {
      titulo: "Oportunidades",
      subtitulo:
        "Descubre oportunidades para crecer profesionalmente y colaborar con otros expertos del sector.",
      icono: <Work sx={{ color: "#9C27B0" }} />,
    },
  ],
};


const NavegacionInternaSoluciones = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "auto",
        backgroundColor: "#fff",
      }}
    >
      {/* Hijo de 70% */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "65%",
          marginLeft: "10%",
          marginRight: "1%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
            position: "relative",
            marginTop: "40px",
            marginBottom: "60px",
          }}
        >
          {/* Título */}
          <Typography
            variant="h4"
            sx={{
              fontWeight: "400",
              fontSize: "1.1rem",
            }}
          >
            Soluciones
          </Typography>

          {/* Línea horizontal */}
          <Box
            sx={{
              width: "100%",
              height: "0.5px",
              backgroundColor: "#ddd",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          ></Box>

          {/* Grid con columnas */}
          <Grid container spacing={2}>
            {/* Columna 1 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA MARCAS Y PEQUEÑAS EMPRESAS
              </Typography>
              {/* Items */}
              {SOLUCIONES.marcasYEmpresas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.85rem",
                        width: "90%",
                        "&:hover": {
                          color: "#F21C63",
                        },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 1,
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Grid>

            {/* Columna 2 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA GRANDES EMPRESAS
              </Typography>
              {/* Items */}
              {SOLUCIONES.profesionales.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box sx={{cursor: "pointer",}}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.85rem",
                        width: "90%",
                        "&:hover": {
                          color: "#F21C63",
                        },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Hijo de 30% */}
      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          width: "35%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
          paddingLeft: "5%",
        }}
      >
        {/* Enlaces */}
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Noticias Importantes
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            marginBottom: "10px",
            fontWeight: "500",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Recursos de Crecimiento
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Asociasiones tecnológicas
        </Link>
      </Box>
    </Box>
  );
};

export default NavegacionInternaSoluciones;
