// AsesoriaPersonalizada.js
import React from "react";
import { Button } from "@mui/material";

const AsesoriaPersonalizada = () => {
  return (
    <Button
      sx={{
        backgroundColor: "#F21C63",
        color: "#fff",
        borderRadius: "5px",
        padding: "10px 20px",
        fontSize: "16px",
        fontWeight: "500 !important",
        boxShadow: "none",
        transition: "all 0.3s ease",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#bf0a46",
        },
      }}
    >
      Asesoria Personalizada
    </Button>
  );
};

export default AsesoriaPersonalizada;
